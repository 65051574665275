<template lang="pug">
  div
    el-row
      el-col
        h3.title Configuración de agencias
    el-row
      el-col
        h4 Agencias B2C
        data-table(
          :headers="b2cHeaders"
          :items="b2cAgencies"
          :shownActions="false"
          :loading="loading"
          :height="450"
        )
</template>

<script>
import DataTable from '@/components/tables/DataTable.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';

export default {
  name: 'Agencies',
  components: {
    DataTable,
  },
  data() {
    return {
      b2cHeaders: [
        { key: 'name', label: 'Nombre' },
        { key: 'internal_name', label: 'Nombres internos' },
      ],
      b2cAgencies: [],
      loading: false,
      submitLoading: false,
    };
  },
  beforeMount() {
    this.fetchAgencies();
  },
  methods: {
    fetchAgencies() {
      this.loading = true;
      FinanceApi.getAgencies().then((data) => {
        this.b2cAgencies = data.body;
        this.loading = false;
      }).catch(() => { this.loading = false; });
    },
  },
};
</script>
